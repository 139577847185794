import React from "react"
import { useState } from 'react'
import { useNavigate } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import planianLogo from "./assets/planian_logo.svg"

const Login = (props) => {
  const navigate = useNavigate();
  const true_username = process.env.REACT_APP_ADMIN;
  const true_password = process.env.REACT_APP_PASSWORD;
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');


  const handleFormSubmit = async (event) => {
    event.preventDefault()

    if (username === '' || password === '') {
      alert('Please enter a valid username and password');
      return;
    }

    if (username === true_username && password === true_password) {
      props.setLoggedIn(true);
      localStorage.setItem('loggedIn', 'true'); // Store login state in localStorage
      navigate("/");
    }
    else {
      alert('Incorrect username or password');
    }
  }

  return <div className="main-container center-content">
    <div className="home-header">
      <img src={planianLogo} style={{ width: "120px" }} alt="Planian logo" />
      <h1>
        <b>Admin Dashboard</b>
      </h1>
    </div>

    <Form className="center-content mt-5" onSubmit={handleFormSubmit}>
      <Form.Group className="mb-3" controlId="formBasicUsename">
        <Form.Control
          type="username"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Control
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </Form.Group>
      <button className="login-button submit-button mt-2" variant="primary" type="submit">
        Log in
      </button>
    </Form>
  </div>
}

export default Login