import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom";
import { createClient } from '@supabase/supabase-js';
import ClientsTable from "./dashboard/ClientsTable"
import PlotNewClients from "./dashboard/PlotNewClients"
import planianLogo from "./assets/planian_logo.svg"
import Nav from 'react-bootstrap/Nav';
import AdminList from "./dashboard/AdminList";

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL
const supabaseKey = process.env.REACT_APP_SUPABASE_KEY
const supabase = createClient(supabaseUrl, supabaseKey);

function checkIfAdmin(email, adminEmails) {
  const trimmedEmail = email.trim();
  return adminEmails.includes(trimmedEmail);
}

function getClientsOnly(data, adminEmails) {
  if (!data) {
    return null;
  }
  return data.filter((user) => {
    return !checkIfAdmin(user.email, adminEmails);
  });
}

const Home = (props) => {
  const [userData, setUserData] = useState(null);
  const [filteredData, setFilteredData] = useState(null);
  const [adminList, setAdminList] = useState(null);
  const [adminEmails, setAdminEmails] = useState(null);
  const tableName = 'users-info';
  const adminTableName = 'admin-list';
  const navigate = useNavigate();

  useEffect(() => {
    if (!props.loggedIn) {
      navigate("/login");
    }
  }, [props.loggedIn, navigate]);

  useEffect(() => {
    fetchUserDataFromTable();
    fetchAdminListFromTable();
  }, []);

  useEffect(() => {
    if (adminList) {
      const emails = adminList.map((admin) => admin.email);
      setAdminEmails(emails);
    }
  }, [adminList]);

  useEffect(() => {
    if (props.eventKey === 'clients-only') {
      setFilteredData(getClientsOnly(userData, adminEmails));
    } else {
      setFilteredData(userData);
    }
  }, [props.eventKey, userData, adminEmails]);

  async function fetchUserDataFromTable() {
    const { data } = await supabase.from(tableName).select();
    setUserData(data);
  }

  async function fetchAdminListFromTable() {
    const { data } = await supabase.from(adminTableName).select();
    setAdminList(data);
  }

  async function addAdmin(email) {
    await supabase.from(adminTableName).insert({ email: email });
    fetchAdminListFromTable();
  }

  async function removeAdmin(email) {
    await supabase
      .from(adminTableName)
      .delete()
      .match({ email });
    fetchAdminListFromTable();
  }

  return (
    props.loggedIn ?
      <div className="main-container center-content">
        <div className="home-header">
          <img src={planianLogo} style={{ width: "120px" }} alt="Planian logo" />
          <h1>
            <b>Admin Dashboard</b>
          </h1>
        </div>

        <Nav variant="underline"
          defaultActiveKey={props.eventKey}
          onSelect={(selectedKey) => props.setEventKey(selectedKey)}
        >
          <Nav.Item>
            <Nav.Link eventKey="show-all">Show all entries</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="clients-only">Show clients only</Nav.Link>
          </Nav.Item>
        </Nav>
        <br />

        {/* <h2>New sign-ups on landing page</h2> */}

        <PlotNewClients userData={filteredData} />
        <br />

        <h3>Users details</h3>
        <ClientsTable userData={filteredData} />
        <br />

        <AdminList adminList={adminList} addAdmin={addAdmin} removeAdmin={removeAdmin} />
        <br />

        <button className="logout-button submit-button mt-2" onClick={() => {
          props.setLoggedIn(false);
          localStorage.setItem('loggedIn', 'false'); // Store login state in localStorage
          navigate("/login");
        }
        }>
          Log out
        </button>
        <br />

      </div>
      :
      null
  );
}

export default Home