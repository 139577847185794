import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './Home';
import Login from './Login';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useState } from 'react'

function App() {
  const [eventKey, setEventKey] = useState('show-all');
  // const [loggedIn, setLoggedIn] = useState(false);
  const [loggedIn, setLoggedIn] = useState(localStorage.getItem('loggedIn') === 'true');

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/"
            element={<Home
              eventKey={eventKey} setEventKey={setEventKey}
              loggedIn={loggedIn} setLoggedIn={setLoggedIn}
            />}
          />
          <Route path="/login" 
            element={<Login 
              loggedIn={loggedIn} setLoggedIn={setLoggedIn} 
            />}
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;