import React, { useState } from 'react';

const AdminList = ({ adminList, addAdmin, removeAdmin }) => {
  const [newEmail, setNewEmail] = useState("");

  console.log(adminList);

  const handleAddEmail = (event) => {
    event.preventDefault();
    if (adminList.map((admin) => admin.email).includes(newEmail)) {
      alert('This email is already in the admin list');
      return;
    }
    addAdmin(newEmail);
    setNewEmail("");
  };

  const handleRemoveEmail = (email) => {
    removeAdmin(email);
  };

  if (!adminList) {
    return <div>Loading...</div>
  }

  return (
    <div className='admin-section center-content'>
      <h3>Admin list</h3>
      <br />

      <div className='admin-list-container'>
        {adminList.map((admin) => (
          <div key={admin.id} className='admin-list'>
            {admin.email} <button className='remove-admin-button' onClick={() => handleRemoveEmail(admin.email)}>Remove</button>
          </div>
        ))}
      </div>
      <br />

      <form className='new-admin-form' onSubmit={handleAddEmail}>
        <input
          id='new-admin-email'
          className='new-admin-input'
          placeholder='Email address'
          type="email"
          value={newEmail}
          onChange={(event) => setNewEmail(event.target.value)}
          required
        />
        <button className='add-admin-button' type="submit" >
          Add admin
        </button>
      </form>
    </div>
  );
};

export default AdminList;