import React, { useEffect, useState } from 'react';
import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import { BarChart } from './BarChart';

Chart.register(CategoryScale);

function getDatesInMonth(month, year) {
  let numDays = moment(month + '-' + year, "MM-YYYY").daysInMonth();
  let datesInMonth = [];
  for (let i = 1; i <= numDays; i++) {
    datesInMonth.push(i + "-" + month + "-" + year);
  }
  return datesInMonth;
}

const PlotNewClients = ({ userData }) => {
  const dateFormat = 'DD-MM-YYYY';
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedMonth, setSelectedMonth] = useState(selectedDate.getMonth() + 1);
  const [selectedYear, setSelectedYear] = useState(selectedDate.getFullYear());

  useEffect(() => {
    setSelectedMonth(selectedDate.getMonth() + 1);
    setSelectedYear(selectedDate.getFullYear());
  }, [selectedDate]);

  if (!userData) {
    return <div>Loading...</div>
  }

  // extract the dates from the data
  let dates = [];
  if (userData) {
    dates = userData.map((user) => {
      return moment(user.created_at).format(dateFormat);
    });
  }

  // count the occurences of each date
  const occurrences = dates.reduce((acc, curr) => {
    acc[curr] ? acc[curr]++ : acc[curr] = 1;
    return acc;
  }, {});

  // count the data occurences for the selected month
  const filteredOccurrences = Object.entries(occurrences).reduce((acc, [date, count]) => {
    if (date.includes(selectedMonth + '-' + selectedYear)) {
      acc[date] = count;
    }
    return acc;
  }, {});

  // Prepare data for the chart
  const filteredLabels = Object.keys(filteredOccurrences);

  const allDatesInMonth = getDatesInMonth(selectedMonth, selectedYear);

  const labelDates = allDatesInMonth.map((date) => {
    return moment(date, dateFormat).format(dateFormat);
  });

  const labelsDays = allDatesInMonth.map((date) => {
    return moment(date, dateFormat).format('DD');
  });

  const numberOfOccurences = labelDates.map((label) => {
    if (filteredLabels.includes(label)) {
      return filteredOccurrences[label];
    }
    return 0;
  });

  const chartData = {
    labels: labelsDays,
    datasets: [
      {
        label: "New users",
        data: numberOfOccurences,
        borderColor: "#32BCB0",
        backgroundColor: "#D6F2EF",
        borderWidth: 1
      }
    ]
  };

  // Group dates by week and sum occurrences
  const weeklyOccurrences = labelDates.reduce((acc, label, index) => {
    const date = moment(label, dateFormat);
    const firstDayOfMonth = date.clone().startOf('month');
    const firstMonday = firstDayOfMonth.day() <= 1 ? firstDayOfMonth.day(1) : firstDayOfMonth.add(1, 'week').day(1);
    let weekOfMonth = Math.floor((date.date() - firstMonday.date()) / 7);
    if (firstDayOfMonth.day() !== 1) {
      weekOfMonth += 1;
    }
    if (!acc[weekOfMonth]) {
      acc[weekOfMonth] = { sum: 0, dates: [] };
    }
    acc[weekOfMonth].sum += numberOfOccurences[index];
    acc[weekOfMonth].dates.push(date.date());
    return acc;
  }, {});

  // Prepare data for the chart
  const filteredWeeks = Object.keys(weeklyOccurrences).sort((a, b) => a - b);

  const labelsWeeks = filteredWeeks.map((week) => {
    // Get the first and last day of the week
    const dates = weeklyOccurrences[week].dates;
    dates.sort((a, b) => a - b);
    const monthName = moment().month(moment(labelDates[0], dateFormat).month()).format('MMM');
    return `${monthName} ${dates[0]}-${dates[dates.length - 1]}`;
  });

  const numberOfWeeklyOccurences = filteredWeeks.map((week) => {
    return weeklyOccurrences[week].sum;
  });

  const chartDataWeekly = {
    labels: labelsWeeks,
    datasets: [
      {
        label: "New users",
        data: numberOfWeeklyOccurences,
        borderColor: "#32BCB0",
        backgroundColor: "#D6F2EF",
        borderWidth: 1
      }
    ]
  };

  // Calculate monthly occurrences for the selected year
  const monthlyOccurrences = Object.entries(occurrences).reduce((acc, [date, count]) => {
    const dateMoment = moment(date, dateFormat);
    if (dateMoment.format('YYYY') === selectedYear.toString()) {
      const month = dateMoment.format('MM-YYYY');
      acc[month] = (acc[month] || 0) + count;
    }
    return acc;
  }, {});

  // Create an array of all months in a year
  const allMonths = Array.from({ length: 12 }, (v, i) => moment(`${selectedYear}`).month(i).format('MM-YYYY'));

  // Map allMonths to monthlyOccurrences
  const numberOfMonthlyOccurences = allMonths.map(month => {
    return monthlyOccurrences[month] || 0;
  });


  // Get labels and data for the monthly chart
  const labelsMonths = allMonths.map(month => moment(month, 'MM-YYYY').format('MMM'));

  // Create chart data for the monthly chart
  const chartDataMonthly = {
    labels: labelsMonths,
    datasets: [
      {
        label: "New users",
        data: numberOfMonthlyOccurences,
        borderColor: "#32BCB0",
        backgroundColor: "#D6F2EF",
        borderWidth: 1
      }
    ]
  };

  const stringMonthYear = selectedDate
    .toLocaleString('en-us', { month: 'long', year: 'numeric' });

  const datePicker = <DatePicker
    id='date-picker'
    className='custom-date-picker'
    selected={selectedDate}
    onChange={date => setSelectedDate(date)}
    dateFormat="MMM yyyy"
    showMonthYearPicker
  />

  return (
    <div className='center-content'>
      <h3>Users gained in {stringMonthYear}</h3>
        <br />

      <div className="select-date">
        <label htmlFor='date-picker'>Select month</label>
        {datePicker}
        <br />
      </div>

      <BarChart
        chartData={chartData}
        titleDate={stringMonthYear}
        title="Daily"
      />

      <BarChart
        chartData={chartDataWeekly}
        titleDate={stringMonthYear}
        title="Weekly"
      />

      <BarChart
        chartData={chartDataMonthly}
        titleDate={selectedYear}
        title="Monthly"
      />
    </div>
  )
};

export default PlotNewClients;