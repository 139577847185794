import React from 'react';
import Table from 'react-bootstrap/Table';
import moment from 'moment';
import { useState, useEffect } from 'react';
import Papa from 'papaparse';

function downloadCSV(userData) {
  const csv = Papa.unparse(userData);
  const csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  const csvURL = window.URL.createObjectURL(csvData);
  let tempLink = document.createElement('a');
  tempLink.href = csvURL;
  tempLink.setAttribute('download', 'data.csv');
  tempLink.click();
}

const FullTable = ({ userData }) => {

  const [selectedUserType, setSelectedUserType] = useState('all');
  const [areFiltersVisible, setFiltersVisible] = useState(false);
  const [isDateFilterEnabled, setDateFilterEnabled] = useState(false);
  const [selectedStartDate, setSelectedStartDate] = useState('');
  const [selectedEndDate, setSelectedEndDate] = useState('');

  useEffect(() => {
    if (!areFiltersVisible) {
      setSelectedStartDate('');
      setSelectedEndDate('');
      setSelectedUserType('all');
    }
  }, [areFiltersVisible]);

  if (!userData) {
    return <div>Loading...</div>
  }

  return (
    <div className='center-content'>
      <div className='filters-switch-container'>
        <div className='filters-switch'>
          <span>Filter search</span>
          <label className="switch" htmlFor='filter-search'>
            <input id='filter-search' type="checkbox" checked={areFiltersVisible} onChange={() => setFiltersVisible(!areFiltersVisible)} />
            <span className="slider round"></span>
          </label>
        </div>
      </div>

      {areFiltersVisible && (
        <div className='filters-container'>
          <div className='filter center-content'>
            <label className='filter-label' htmlFor="user-type">User type: </label>
            <select id='user-type' value={selectedUserType}
              onChange={e => setSelectedUserType(e.target.value)}
            >
              <option value="all">All</option>
              <option value="candidate">Candidate</option>
              <option value="employer">Employer</option>
            </select>
          </div>

          <div className='date-filters'>

            <div className='filter center-content'>
              <label className='filter-label' htmlFor="date-filter">Filter by date: </label>
              <input type="checkbox" id="date-filter" checked={isDateFilterEnabled}
                onChange={e => setDateFilterEnabled(e.target.checked)}
              />
            </div>

            {isDateFilterEnabled && (
              <>
                <div className='filter center-content'>
                  <label className='filter-label' htmlFor="start-date">Start date: </label>
                  <input type="date" id="start-date" value={selectedStartDate}
                    onChange={e => setSelectedStartDate(e.target.value)}
                  />
                </div>

                <div className='filter center-content'>
                  <label className='filter-label' htmlFor="end-date">End date: </label>
                  <input type="date" id="end-date" value={selectedEndDate}
                    onChange={e => setSelectedEndDate(e.target.value)}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      )}
      <br />

      <Table className="full-table" striped bordered hover responsive>
        <thead>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>User type</th>
            <th>Email</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          {userData && (() => {
            let count = 1;
            return userData
              .filter(user => selectedUserType === 'all' || user.user_type === selectedUserType)
              .filter(user => {
                if (selectedStartDate && selectedEndDate) {
                  let date = new Date(user.created_at);
                  let startDate = new Date(selectedStartDate);
                  let nextDay = new Date(selectedEndDate);
                  nextDay.setDate(nextDay.getDate() + 1);
                  return date >= startDate && date <= nextDay;
                }
                return true;
              })
              .map((user) => (
                <tr key={user.id}>
                  <th>{count++}</th>
                  <th>{user.first_name + " " + user.last_name}</th>
                  <th>{user.user_type}</th>
                  <th>{user.email}</th>
                  <th>{moment(user.created_at).format('DD.MM.YYYY')}</th>
                </tr>
              ));
          })()}
        </tbody>
      </Table>

      <div className='download-button-container'>
        <button className='download-button' onClick={() => downloadCSV(userData)}>
          Download all data as CSV
        </button>
      </div>
    </div>
  )
}

export default FullTable;