import React from 'react';
import { Bar } from "react-chartjs-2";

export const BarChart = ({ chartData, titleDate, title }) => {
  return (
    <div className="chart-container" >
      <Bar
        data={chartData}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            title: {
              display: true,
              // text: "Users gained in " + titleDate,
              text: title,
            },
            legend: {
              display: false
            }
          }
        }}
      />
    </div>
  );
};